import React, { Fragment, useContext, useEffect, useState } from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';
import {switchRole}  from '../../../shared/roles';
import {roles} from './clientConfig';
import Avatar from 'react-avatar';
import { removeNumbersAndSpecialCharacters } from '../../../utils/utils';
import '../../../assets/css/ClientFormCustom.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyContext } from '../../../context/companyContext';
import jwtDecode from "jwt-decode";
import { PHONE } from '../../../shared/contactTypes';

const ClientFormCusmon = (props) => {
    const [fields, setFields] = useState([]);
    const company_context = useContext(CompanyContext);
    const token = localStorage.getItem('token');
    const decodedJwt = jwtDecode(token);
    const [clientName, setClientName] = useState('');
    const [clientPhones, setClientPhones] = useState([]);

    const state = {
        uri: '/debtors',
        resource_id: props.id,
        action: props.id ? 'edit' : 'create',
        fields: [
            {
                name: 'document_number', 
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Identificador'
            },
            {
                name: 'lastname',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                },
                value: '',
                traduction: 'Apellido'

            },
            {
                name: 'firstname',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre'

            },
            {
                name: 'cuil',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'CUIL'

            },
            {
                name: 'extra_info_1',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                },
                value: null,
                traduction: 'Info Extra 1'

            },
            {
                name: 'extra_info_2',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                },
                value: null,
                traduction: 'Info Extra 2'

            },
            {
                name: 'extra_info_3',
                elementType: 'text',
                elementClass: 'col',
                copy: props.id ? true : false,
                elementConfig: {
                },
                value: null,
                traduction: 'Info Extra 3'

            },
            {
                name: 'user',
                elementType: 'dropdown',
                elementClass: 'col',
                elementConfig: {
                    options: (!(!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole()))) ? [
                        {
                            'value': decodedJwt.user_claims.user_id,
                            'label': decodedJwt.identity + ' (Tú)'
                        }
                    ] : null,
                    placeholder: 'Seleccionar Usuario'
                },
                value: null,
                traduction: 'Seleccionar Usuario',
                ...((!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole()))) && {
                    requestResource: {
                        request:{
                            url: '/conversations/users',
                            params: {},
                        },
                        format: {
                            value: 'id',
                            label: 'username'
                        }
                    }
                }
            },
            {
                name: 'group',
                elementType: 'dropdown',
                elementClass: 'col',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccionar Grupo'
                },
                value: null,
                traduction: 'Seleccionar Grupo',
                requestResource: {
                    request:{
                        url: '/groups',
                        params: {},
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            },
            {
                name: 'notification_subscription',
                elementType: 'checkbox',
                value: false,
                traduction: 'Estado de notificaciones',
                elementConfig: {
                }
            },
            {
                name: 'contacts',
                elementType: 'FormList',
                elementConfig: {
                    childFields: [
                        {
                            name: 'contact_type_id',
                            elementType: 'dropdown',
                            elementClass: 'col',
                            elementConfig: {
                                options: null,
                                placeholder: 'Seleccione un Tipo de Contacto'
                            },
                            value: '',
                            traduction: 'Tipo de Contacto',
                            requestResource: {
                                request:{
                                    url: '/contact_types',
                                    params: {},
                                },
                                format: {
                                    value: 'id',
                                    label: 'name'
                                }
                            }
                        },
                        {
                            name: 'country_prefix',
                            elementType: 'hidden',
                            elementClass: 'col',
                            elementConfig: {
                            }
                        },
                        {
                            name: 'value',
                            elementType: 'contact',
                            elementConfig: {
                            },
                            dependence_of: [
                                'contact_type_id',
                                'country_prefix'
                            ],
                            traduction: 'Contacto'
                        },
                        {
                            name: 'id',
                            elementType: 'hidden',
                            elementConfig: {
                            }
                        }

                    ],
                    elements: 1
                },
                value: [],
                panelLabel: 'Contactos',
                panelChildren: "Nuevo Contacto"
            },
            {
                name: 'provider_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un cliente'
                },
                value: '',
                traduction: 'Cliente',
                requestResource: {
                    request:{
                        url: '/clients',
                        params: {},
                        filters: [
                            {
                                name: "available",
                                op: "eq",
                                val: true
                            }
                        ],
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            }
        ],
        loading: false
    };

    useEffect(() => {
        let {fields} = state;
        if (props.id) {
            fields = fields.filter((item) => item.name !== 'user' && item.name !== 'group');
        }
        if(roles[switchRole()]){
            fields = fields.filter(field => {
                return !roles[switchRole()].exclude_fields.includes(field.name)
            })
        }
        setFields(fields);
    }, []);

    const copyValue = (value) => {
        return(
            <CopyToClipboard style={{bottom: '8px'}} text={value}>
                <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'} size="xs"
                                 className={"btn-actions btn-fontawesome"} />
            </CopyToClipboard>
        )
    }

    useEffect(() => {
        let clientFirstname = fields.find(item => item.name === 'firstname');
        let clientLastname = fields.find(item => item.name === 'lastname');
        let clientContacts = fields.find(item => item.name === 'contacts')?.value || [];
        let clientPhone = [];
        clientContacts.map((element) => {
            if (element.filter(item => (item.name === 'contact_type_id' && item.value === PHONE))){
                clientPhone.push(element.find(item => item.name === 'value')?.value?.value);
            }
        })
        setClientName(clientFirstname ? `${clientFirstname.value} ${clientLastname ? clientLastname.value : ''}` : '' )
        setClientPhones(clientPhone.filter(item => item !== undefined))
    },[fields])
        
    const form = (<ResourceFormGeneric 
                    setFields={setFields}
                    fields={fields}
                    url={'/debtors'}
                    resource_id={state.resource_id}
                    resource_traduction={state.title}
                    hasHistory={false}
                />);

    const info = (
        <div className='info-contact-flex'>
            {clientName && (
                <Avatar className="conversation-photo info-avatar" 
                        maxInitials={1} 
                        name={removeNumbersAndSpecialCharacters(clientName)}
                        round={true} 
                        size="80"/>
            )}
            <div className='info-padding'>
                {clientName && (
                    <div className='info-flex'>
                        <h1 className="toolbar-title">
                            {clientName}
                        </h1>
                        {copyValue(clientName)}
                    </div>
                )}
                {!clientPhones[0] ?
                    <span className='red-tag' >Sin Número</span> :
                    <div className='info-flex'>
                        <p> {clientPhones[0]} </p>
                        {copyValue(clientPhones[0])}
                    </div>
                }
            </div>
        </div>  
    );

    return (
        <Fragment>
            {props?.client && info}
            {!state.loading && form}
        </Fragment>
        );
    }

export default ClientFormCusmon;